<template>
	<div class="container">
		<div class="content flex flex-between">
			<div class="ft-topic">
				<template v-if="questionType == 1">
					<div class="top">
						<div class="topic">【单选题】<span class="integral">（1分）</span></div>
						<h3 class="title" v-if="danList[questionNum]">{{num}}.{{danList[questionNum].title}}</h3>
					</div>
					<div class="bottom">
						<div class="answer-choose">
							<el-radio-group v-model="checkAnswerDan" v-if="danList.length">
								<el-radio 
								v-for="(item,index) in danList[questionNum].answerList" 
								:key="item.code" 
								:disabled="showAnswer"
								:label="item.code" >
								<div>{{index | NumFilter}}</div>						
									{{item.description}}
								</el-radio>		
							</el-radio-group>
						</div>
					</div>
				</template>
				<template v-if="questionType == 2">
					<div class="top	">
						<div class="topic">【多选题】<span class="integral">（2分）</span></div>
						<h3 class="title" v-if="duoList[questionNum]">{{num}}.{{duoList[questionNum].title}}</h3>
					</div>
					<div class="bottom">
						<div class="answer-choose">	
							<el-checkbox-group v-model="checkAnswerDuo" :min="0" v-if="duoList.length">
								<el-checkbox 
								v-for="(item,index) in duoList[questionNum].answerList" 
								:key="item.code"
								:disabled="showAnswer"
								:label="item.code" >
									<div>{{index | NumFilter}}</div>
									{{item.description}}	
								</el-checkbox>
							</el-checkbox-group>
						</div>						
					</div>
				</template>
				<template v-if="questionType == 3">
					<div class="top">
						<div class="topic">【判断题】<span class="integral">（2分）</span></div>
						<h3 class="title" v-if="panList[questionNum]">{{num}}.{{panList[questionNum].title}}</h3>
					</div>
					<div class="bottom">
						<div class="answer-choose">
							<el-radio-group v-model="checkAnswerPan" v-if="panList.length">
								<el-radio 
								v-for="item in panAnswerList" 
								:key="item.code" 
								:disabled="showAnswer"
								:label="item.code">						
									{{item.description}}
								</el-radio>		
							</el-radio-group>
						</div>
					</div>
				</template>
				<div class="public-block">
					<el-button type="primary" class="submitBtn" v-preventReClick @click="getRightAnswer()" v-show="!showAnswer">提交答案</el-button>	
					<el-button type="primary" class="next" @click="next()" :disabled="!showAnswer">下一题</el-button>
					
					<div class="answer" v-if="showAnswer">
						<h4 class="labels">【答案】：</h4>
						<div class="sure-answer">正确答案：<span>{{standardAnswer.options}}</span></div>
						<div class="your-answer">你的答案：<span>{{selectedAnswer.options}}</span></div>
					</div>
					<div class="state" v-if="showAnswer">
						<img :src="backAnswer.isRight == 1 ? imgUrl : imgUrl2">
					</div>
				</div>
						
				
			</div>
			
			<div class="rt-results">
				<div class="top">
					<div class="upblock flex flex-between">
						<div class="answered">
							已答<span class="cur">{{finishedNum}}</span>/<span class="total">{{danList.length + duoList.length + panList.length}}</span>
						</div>
						<div class="correct">    
							正确率：<span>{{correct}}</span>
						</div>
					</div>
					<div class="downblock flex flex-between">
						<div class="answer-results flex flex-start">
							<div class="right-topic">{{success}}</div>
							<div class="wrong-topic">{{fail}}</div>
						</div>
						<div class="uniform">
							得分：<span class="cur">{{score}}</span>/<span class="total">{{danList.length*1 + duoList.length*2 + panList.length*2}}</span>
						</div>
					</div>
				</div>
				<div class="bottom">
					<div class="topic radio">
						<div class="title">单选题：</div>
						<ul>
							<li v-for="(item,index) in danList" :key="item.questionId" :class="num == index+1? 'cur' : ''">
								<div class="order-num green" v-if="item.status==1">{{index+1}}</div>
								<div class="order-num red" v-else-if="item.status==2">{{index+1}}</div>
								<div class="order-num" v-else>{{index+1}}</div>
							</li>
						</ul>
					</div>
					<div class="topic multi">
						<div class="title">多选题：</div>
						<ul>
							<li v-for="(item,index) in duoList" :key="item.questionId" :class="num == danList.length+index+1? 'cur' : ''">
								<div class="order-num green" v-if="item.status==1">{{danList.length+index+1}}</div>
								<div class="order-num red" v-else-if="item.status==2">{{danList.length+index+1}}</div>
								<div class="order-num" v-else>{{danList.length+index+1}}</div>
							</li>
						</ul>
					</div>
					<div class="topic radio">
						<div class="title">判断题：</div>
						<ul>
							<li v-for="(item,index) in panList" :key="item.questionId" :class="num == duoList.length+danList.length+index+1? 'cur' : ''">
								<div class="order-num green" v-if="item.status==1">{{duoList.length+danList.length+index+1}}</div>
								<div class="order-num red" v-else-if="item.status==2">{{duoList.length+danList.length+index+1}}</div>
								<div class="order-num" v-else>{{duoList.length+danList.length+index+1}}</div>
							</li>
						</ul>
					</div>				
				</div>
				<div class="reset-topic" @click="react()">重新答题</div>
			</div>
		</div>

		<el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      class="dialogbox"
      width="450px"
    >      
      <div class="close" @click="dialogVisible = false">
        <img src="@/assets/icons/grayclose.png" />
      </div>
      <div class="dialog-content">
        <div class="img"><img src="@/assets/images/img-certificate.png" alt=""></div>
        <div class="text">本次模拟考试已完成!</div>
				<div class="results">
          <h3>您的测试成绩如下：</h3>
          <p>
						<span>得分：{{score}}</span>
            <span>正确：{{success}} 题</span>
            <span>错误：{{fail}} 题</span>
          </p>
        </div>
				<el-button type="primary" class="signup" @click="$router.push({name:'PracticeTest'})">继续考试</el-button>      
      </div>
    </el-dialog>
	</div>
</template>

<script>
	
	export default {
		filters: {
			NumFilter: function (index) {
				const numWord={0:'A.',1:'B.',2:'C.',3:'D.',4:'E.',5:'F.',6:'G.',7:'H.',8:'I.',9:'J.',10:'K.',11:'L.',12:'M.'};
				return numWord[index]			
			},
		},
		data() {
			return {
				showAnswer:false,	
				dialogVisible:false,
				checkAnswerDan: null,
				checkAnswerDuo:[],
				checkAnswerPan: null,
				selectedAnswer:{
					code:'',
					options:'',
				},
				standardAnswer:{
					code:'',
					options:'',
				},
				rightAnswer: '',		
				danList: [],
				duoList: [],
				panList: [],
				panAnswerList:[
					{description: "是", code: "1"},
					{description: "否", code: "2"}
				],
				questionType:1,//题型
				questionNum:0,//题号索引
				score:0,
				success:0,
				fail:0,	
				num:1,//题号
				correct:0,
				finishedNum:0,
				imgUrl: require('@/assets/icons/state-success.png'),
				imgUrl2: require('@/assets/icons/state-error.png'),
				backAnswer:{},
				rightData:{
					questionId:'',
					answerCodeArr:''
				}
			}
		},
		created() {
		  let	ipType = this.$route.query.ipType
			this.getQuestionList(ipType);		 
		},
		methods: {					
		 	getRightAnswer(){	
				let data={
					questionId:this.danList[this.questionNum].questionId,
					answerCodeArr:this.selectedAnswer.code
				}								
				if(this.questionType == 1 && !!this.checkAnswerDan){
					this.selectedAnswer.code = this.checkAnswerDan;	
					data.answerCodeArr=this.checkAnswerDan			
				}else if(this.questionType == 2 && !!this.checkAnswerDuo.length){	
					this.selectedAnswer.code = this.checkAnswerDuo;	
					data.questionId=this.duoList[this.questionNum].questionId	
					data.answerCodeArr=this.checkAnswerDuo																		
				}else if(this.questionType == 3 && !!this.checkAnswerPan){
					this.selectedAnswer.code = this.checkAnswerPan;			
					data.questionId=this.panList[this.questionNum].questionId	
					data.answerCodeArr=this.checkAnswerPan	
				}	else {
					this.$toast('请选择答案');						
					return;
				}	
							
				this.$http
        	.post("/api/web/question/getRightAnswer",data)
        	.then((res) => {
						this.backAnswer=res.data;
						// console.log(this.backAnswer)
						this.showAnswer=true;
						this.finishedNum = this.finishedNum + 1;
						const numWord={0:'A',1:'B',2:'C',3:'D',4:'E',5:'F',6:'G',7:'H',8:'I',9:'J',10:'K',11:'L',12:'M'};
						const panWord={0:'是',1:'否'};
						let topicScore=1;
						if (this.questionType == 1) {
							let index=this.danList[this.questionNum].answerList.findIndex(item=>item.code==this.selectedAnswer.code);
							let indexP = this.danList[this.questionNum].answerList.findIndex(item=>item.code==this.backAnswer.rightAnswerCodeList[0]);
							this.selectedAnswer.options=numWord[index];
							this.standardAnswer.options=numWord[indexP];
							this.danList[this.questionNum].status=!this.backAnswer.isRight?2:1;
							topicScore=1;
						} else if(this.questionType == 2) {
							let indexArr=this.duoList[this.questionNum].answerList.map((item,index)=>{
								if(this.selectedAnswer.code.indexOf(item.code)>-1){
									return numWord[index];
								}
							}).filter(item=>!!item).join(',')
							let indexArrP=this.duoList[this.questionNum].answerList.map((item,index)=>{
								if(this.backAnswer.rightAnswerCodeList.indexOf(item.code)>-1){
									return numWord[index];
								}
							}).filter(item=>!!item).join(',')			
							this.duoList[this.questionNum].status=!this.backAnswer.isRight?2:1;				
							this.selectedAnswer.options=indexArr;
							this.standardAnswer.options=indexArrP;
							topicScore=2;
						} else{
							let index=this.panAnswerList.findIndex(item=>item.code==this.selectedAnswer.code);
							let indexP = this.panAnswerList.findIndex(item=>item.code==this.backAnswer.rightAnswerCodeList);
							this.selectedAnswer.options=panWord[index];
							this.standardAnswer.options=panWord[indexP];
							this.panList[this.questionNum].status=!this.backAnswer.isRight?2:1;
							topicScore=2;
						}		
						if (this.backAnswer.isRight == 1) {
							this.score = this.score + topicScore;
							this.success = this.success + 1
						} else {
							this.fail = this.fail + 1
						}								
						this.correct = (this.success / this.num * 100).toFixed() + "%"
        	});		
			},			
			getQuestionList(ipType){
				this.$http
        .get("/api/web/question/getQuestionList?ipType=" + ipType)
        .then((res) => {
					const {dan,duo,pan} = res.data.questionList;
          this.danList = dan;
          this.duoList = duo;
					this.panList = pan;				
        });
			},
			next(){						
				this.showAnswer=false;
				this.checkAnswerDan = null;
				this.checkAnswerDuo = [];				
				this.selectedAnswer.code = [];
				let danLength = this.danList.length;
				let duoLength = this.duoList.length;
				const totalLength=this.panList.length+this.danList.length+this.duoList.length;
				if (this.num < danLength) {
					this.questionType = 1
					this.questionNum = this.num	;	
				} else if(this.num < (danLength + duoLength) && this.num >= danLength){
					this.questionType = 2;
					this.questionNum = this.num - danLength;	
				}else if(this.num >= (danLength + duoLength) && this.num < totalLength){
					this.questionType = 3;
					this.questionNum = this.num - danLength - duoLength;
					this.checkAnswerPan = null;
				}else if(this.num == totalLength){
					this.dialogVisible = true;
					this.showAnswer=true;
					this.questionNum = totalLength - danLength - duoLength - 1;
				}	
				this.num = this.num < totalLength ?++this.num:this.num;	
				// console.log(this.questionNum)							
				// console.log(totalLength)		
				// console.log(this.num)
			},
			react(){
				this.$router.go(0)
			}
		}
	}
</script>

<style lang="less" scoped>
	.container {
		width: 100%;
		padding: 30px 0;
		background-color: #F5F5F5;

		.content {
			width: var(--container-width);
			margin: 0 auto;
			/deep/.el-radio-group{
				.el-radio{
					display: flex;
					width: 100%;
				}
				.el-radio__label{
					display: flex;
				}
				.el-radio__inner{
					// border: 1px solid #93969c;
				}
			}
			.ft-topic {
				width: 960px;
				min-height: 630px;
				border-radius: 20px;
				overflow: hidden;
				background-color: #fff;
				.top {
					padding: 30px 40px;
					background-color: #FFF7F7;
					border-radius: 20px 20px 0px 0px;

					.topic {
						width: 100%;
						font-size: 16px;
						color: #333333;
						line-height: 28px;
						text-align: left;

						span {
							font-size: 16px;
							color: #333333;
							line-height: 28px;
						}
					}

					.title {
						width: 100%;
						font-size: 18px;
						color: #333333;
						line-height: 32px;
						font-weight: normal;
						text-align: left;
						margin-bottom: 0;
					}
				}

				.bottom {
					position: relative;
					width: 100%;
					padding: 20px 40px 0;
					background-color: white;

					.answer-choose {
						margin-bottom: 0;
						min-height: 170px;
						text-align: left;

						.el-radio {
							font-size: 16px;
							margin: 20px 0;

							/deep/.el-radio__label {
								display: flex;
								font-size: 15px;
							}
						}
						.el-checkbox {
							display: flex;
							align-items: center;
							font-size: 16px;
							margin: 20px 0 0;

							/deep/.el-checkbox__label {
								display: flex;
								font-size: 15px;
							}
						}
					}		
				}
				.public-block{	
					position: relative;
					width: 100%;
					padding: 40px 40px 100px;
					background-color: #fff;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					.submitBtn{
						width: 100px;
						height: 40px;
						background: white;						
						border: 1px solid #D71A18;
						border-radius: 20px;
						font-size: 16px;
						color: #D71A18;
						line-height: 40px;
						margin-bottom: 20px;
						cursor: pointer;
						padding: 0;
					}
					.next {
						width: 100px;
						height: 40px;
						background: #D71A18;
						border-radius: 20px;
						font-size: 16px;
						color: #FFFFFF;
						line-height: 40px;
						padding: 0;
						margin: 0;
					}
					
					.answer {
						.labels {
							font-size: 16px;
							color: #333333;
							line-height: 40px;
							font-weight: normal;
							text-align: left;
							margin-bottom: 0;
						}
					
						.sure-answer,
						.your-answer {
							width: 100%;
							font-size: 16px;
							color: #333333;
							line-height: 40px;
							text-align: left;
					
							span {
								color: #D71A18;
							}
						}
					}
					
					.state {
						position: absolute;
						left: calc((100% - 166px) / 2);
						bottom: 100px;
						width: 166px;
						height: 166px;
						z-index: 9;
					
						img {
							display: block;
							width: 166px;
						}
					}
				}
			}

			.rt-results {
				width: 300px;
				border-radius: 20px 20px 0px 0px;
				overflow: hidden;

				.top {
					width: 300px;
					padding: 25px 25px;
					background: #FFF7F7;

					.upblock {
						.answered {
							height: 30px;
							font-size: 16px;
							color: #333333;
							line-height: 30px;

							.cur {
								color: #D71A18;
							}

							.total {
								color: #333;
							}
						}

						.correct {
							height: 30px;
							font-size: 16px;
							color: #333333;
							line-height: 30px;

							span {
								color: #D71A18;
							}
						}
					}

					.downblock {
						margin-top: 30px;

						.answer-results {
							.right-topic {
								position: relative;
								height: 28px;
								font-size: 18px;
								color: #4AD71A;
								line-height: 28px;
								padding-left: 20px;
								margin-right: 30px;
							}

							.right-topic::before {
								content: '';
								display: block;
								position: absolute;
								left: 0;
								top: 10px;
								width: 8px;
								height: 8px;
								background: #4AD71A;
								border-radius: 50%;
							}

							.wrong-topic {
								position: relative;
								height: 28px;
								font-size: 18px;
								color: #D71A18;
								padding-left: 20px;
								line-height: 28px;
							}

							.wrong-topic::before {
								content: '';
								display: block;
								position: absolute;
								left: 0;
								top: 10px;
								width: 8px;
								height: 8px;
								background: #D71A18;
								border-radius: 50%;
							}
						}

						.uniform {
							.cur {
								color: #D71A18;
							}
						}
					}
				}

				.bottom {
					width: 100%;
					background-color: white;
					padding: 25px;
					border-radius: 0px 0px 20px 20px;

					.topic {
						margin-bottom: 5px;

						.title {
							font-size: 16px;
							color: #333333;
							text-align: left;
							margin-bottom: 10px;
						}

						ul {
							padding: 0;
							margin: 0;
							display: flex;
							flex-wrap: wrap;
							justify-content: flex-start;

							li {
								width: 32px;
								height: 32px;
								line-height: 30px;
								background: white;
								font-size: 16px;
								color: #CCCCCC;
								margin-right: 22px;
								margin-bottom: 10px;
								list-style: none;
								.order-num{
									border-radius: 20px;
									border: solid 1px #cccccc;
								}
								.red{
									background-color: #D71A18;
									border-color: #D71A18;
									color: #fff;
								}
								.green{
									background-color: #4AD71A;
									border-color: #4AD71A;
									color: #fff;
								}
								&.cur{
									.order-num{
										background-color: #f7b600;
										border-color: #f7b600;
										color: #fff;
									}
									
								}
							}

							li:nth-child(5n) {
								margin-right: 0;
							}
						}
					}
				}

				.reset-topic {
					width: 300px;
					height: 60px;
					font-size: 18px;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 60px;
					margin-top: 40px;
					background: #D71A18;
					border-radius: 30px;
					cursor: pointer;
				}
			}
		}
		/deep/.dialogbox {
    text-align: left;
    
    .el-dialog {
      width: 100%;
       border-radius: 15px;
    overflow: hidden;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      img {
        display: block;
        width: 36px;
        height: 36px;
      }
    }
    .dialog-content {
     
      padding: 40px;
      text-align: center;
        .text {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #d71a18;
        }
				.results{
					h3{font-size: 18px;}
					p{
						font-size: 16px;
						span{margin: 0 20px;}
					}
				}
        .img {
          img {
            display: block;
            width: 175px;
            height: 175px;
            margin: 0 auto 40px;
          }
        }
				.signup{
          width: 125px;
          border-radius: 20px;
          margin-top: 30px;
        }
             
    }
  }
	}
</style>
